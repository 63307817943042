<template>
    <div class="home">
        <div class="list" v-for="item in btn" @click="changeRouter(item.url)">
            <div class="img">
                <img :src="item.img" alt="">
            </div>
            <div class="title">{{ item.menu_name }}</div>
        </div>
    </div>
</template>
<script>
import {wxtriageworkbenchqry} from '@/api/medical'
import { mapGetters } from 'vuex'
export default{
    computed: {
        ...mapGetters([
            'username','dispname','clinicid'
        ]),
    },
    data(){
        return{
            btn:[]
        }
    },
    created(){
        this.$store.dispatch('hideOrShowNav', true)
        this.getmenu()
    },
    beforeRouteLeave(to, from, next){
  
        this.$store.dispatch('changeIsRefresh', '1')

        next()
    },
    methods:{
        changeRouter(url){
            console.log(url);
            if(url){

                this.$router.push(url)
            }
        },
        getmenu(){
            let data ={
                username:this.username
            }
            wxtriageworkbenchqry(data).then(response =>{
                
                this.btn = response.response_body.list.map(item => {
                    if(item.menu_code == 'treatWorkbench'){
                        item.url = '/treatPage'
                        item.img = require('@/assets/physicalwork.png')
                    }else if(item.menu_code == 'visitWorkbench'){
                        item.url = '/seePage'
                        item.img = require('@/assets/visitwork.png')
                    }else if(item.menu_code == 'patientList'){
                        item.url = '/patientList'
                        item.img = require('@/assets/patientlist.png')
                    }else if(item.menu_code == 'bookingManagement'){
                        item.url = '/bookingManagement'
                        item.img = require('@/assets/bookingManagement.png')
                    }else{
                        item.url = '/registrationDesk'
                        item.img = require('@/assets/reportTriage.png')
                    }
                    return item
                })
                
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.home{
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    
    .list{
        width: 33%;
        text-align: center;
        margin-bottom: 40px;
        .img{
            display: flex;
            justify-content: center;
        }
        img{
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
    }
}
</style>